<template>
  <div class="bg-white p-4">
    <b-row no-gutters>
      <b-col cols="3">
        <rm-input outlined class="mr-2" placeholder="Search..."> </rm-input>
      </b-col>
      <b-col cols="1">
        <rm-base-button text="Search"></rm-base-button>
      </b-col>
      <!-- <b-col cols="6">
        <rm-base-button
          @click="showFilter = !showFilter"
          class="ml-2"
          :text="`${showFilter ? 'Hide' : 'Show'} Filters`"
          bgColor="primary--light"
          textColor="primary"
          ><rm-base-icon slot="post-icon" class="ml-3" name="filter-icon"
        /></rm-base-button>
      </b-col> -->
      <!-- <b-col cols="2">
        <div class="d-flex w-100">
          <rm-base-button
            @click="
              exportData({
                items,
                config: {},
                title: 'Sales Report for Client',
              })
            "
            class="ml-auto"
            text="Export"
            bgColor="primary"
            textColor="white"
            ><rm-base-icon slot="post-icon" class="ml-2" name="export-icon"
          /></rm-base-button>
        </div>
      </b-col> -->
    </b-row>
    <b-row v-if="showFilter">
      <b-col cols="2" class="bordered ml-3">
        <rm-drop-down outlined :options="filterOptions" />
      </b-col>
      <b-col cols="2" class="ml-2">
        <rm-drop-down outlined :options="filterOptions" />
      </b-col>
      <b-col cols="2" class="ml-2">
        <rm-drop-down outlined :options="filterOptions" />
      </b-col>
      <b-col cols="2" class="ml-2">
        <rm-drop-down outlined :options="filterOptions" />
      </b-col>
    </b-row>

    <div class="d-flex mb-5" v-if="showFilter">
      <rm-base-button
        class="ml-3"
        text="Filter"
        bgColor="tertiary"
        textColor="white"
      ></rm-base-button>
      <rm-base-button
        class="ml-3"
        text="Clear Filter"
        bgColor="tertiary--light"
        textColor="tertiary"
      ></rm-base-button>
    </div>
    <b-table
      ref="clientsTable"
      thead-class="bg-gray-400 text-uppercase text-sm-2"
      tbody-class="text-sm"
      :selectable="true"
      hover
      :busy="isLoading"
      :items="items"
      :fields="fields"
      @row-selected="onRowSelected"
    >
      <template #table-busy>
        <div class="text-center text-gray my-5">
          <b-spinner class="align-middle"></b-spinner> <br />
          <strong>Loading Data...</strong>
        </div>
      </template>
      <template v-slot:cell(check)="{ rowSelected, index }">
        <div class="d-flex justify-content-center align-items-center">
          <input
            type="checkbox"
            :checked="rowSelected"
            @click="handleRowSelect(rowSelected, index)"
          />
        </div>
      </template>
      <template #head(check)="">
        <input
          type="checkbox"
          :checked="selectAll && items.length == selected.length"
          @click="selectAll = !selectAll"
        />
      </template>

      <template #cell(status)="data">
        <div
          class="text-center px-3 py-1 rounded"
          :class="data.item.status == 'active' ? 'active' : 'completed'"
        >
          <small>{{ data.item.status }}</small>
        </div>
      </template>

      <!-- Action column -->
      <template #cell(actions)=""></template>
    </b-table>

    <b-pagination
      v-if="paging.totalRows"
      v-model="paging.currentPage"
      :total-rows="paging.totalRows"
      :per-page="paging.perPage"
      aria-controls="my-table"
      @change="changePage"
    />
  </div>
</template>

<script>
import exportData from '@/mixins/exportData.js';
import formatMoney from '../../../mixins/formatMoney';
import formatDate from '@/mixins/formatDate.js';

export default {
  mixins: [formatMoney, exportData, formatDate],
  data() {
    return {
      isLoading: false,
      selectAll: false,
      filterOptions: {},
      selected: [],
      showFilter: false,
      paging: {
        currentPage: 1,
        totalRows: null,
        perPage: null,
      },
      // Note `isActive` is left out and will not appear in the rendered table
      fields: [
        {
          key: 'check',
          label: '',
          class: 'text-center',
        },
        {
          key: 'client_name',
          label: 'Client Name',
          class: 'text-center',
        },
        {
          key: 'email',
          label: 'Email',
          class: 'text-center',
        },
        {
          key: 'referrer',
          sortable: false,
          label: 'Referrer',
          class: 'text-center',
        },
        {
          key: 'property_name',
          sortable: false,
          label: 'Property',
          class: 'text-center',
          // class: "w-25",
        },
        {
          key: 'total_amount',
          sortable: false,
          label: 'Total Amount',
          class: 'text-center',
        },
        {
          key: 'amount_paid',
          sortable: false,
          label: 'Amount Paid',
          class: 'text-center',
        },
        {
          key: 'status',
          sortable: false,
          label: 'Status',
          class: 'text-center',
        },
        {
          key: 'duration',
          sortable: false,
          label: 'Duration',
        },
        {
          key: 'date',
          sortable: false,
          label: 'Date',
        },
      ],
      items: [],
    };
  },
  methods: {
    onRowSelected(items) {
      this.selected = items;
    },
    selectAllRows() {
      this.$refs.clientsTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.clientsTable.clearSelected();
    },
    changePage(pageNo) {
      this.paging.currentPage = pageNo;
      this.loadData();
    },
    selectRow(index) {
      this.$refs.clientsTable.selectRow(index);
    },
    unSelectRow(index) {
      this.$refs.clientsTable.unselectRow(index);
    },
    handleRowSelect(isRowSelected, index) {
      if (!isRowSelected) {
        this.selectRow(index);
      } else {
        this.unSelectRow(index);
      }
    },

    async loadData() {
      this.isLoading = true;
      try {
        const params = {
          ...this.filterOptions,
          page: this.paging.currentPage,
        };
        await this.fetchSales(params);
      } catch (error) {
        // console.log(error);
        this.isLoading = false;
      } finally {
        this.isLoading = false;
      }
    },

    async fetchSales(params) {
      try {
        const { rows, paging } = await this.$store.dispatch('sales/fetchSales', params);
        this.items = rows.map((row) => {
          const { product, customer } = row;
          return {
            client_name: `${customer.first_name} ${customer.last_name}`,
            email: customer.email,
            referrer: `${customer.upline.first_name} ${customer.upline.last_name}`,
            property_name: `${product.location_name} ${product.name === product.location_name ? '' : product.name}`,
            total_amount: `₦${this.$formatCurrency(row.total_amount / 100)}`,
            amount_paid: `₦${this.$formatCurrency(row.amount_paid / 100)}`,
            status: row.status,
            duration: `${row.duration ? row.duration : 0} months`,
            date: this.formatDate(row.created_at) // new Date(row.created_at).toDateString(),
          }
        });
        this.paging.currentPage = paging.currentPageNo;
        this.paging.totalRows = paging.total;
        this.paging.perPage = paging.perPage;
      } catch (err) {
        this.isLoading = false;
        console.log(err);
      }
    },
    // exportData() {
    // 	var csv = this.$papa.unparse(this.items);
    // 	this.$papa.download(csv, "Sales Report for Client");
    // },
    // async fetchSales() {
    //   try {
    //     this.isBusy = true;
    //     this.isLoading = true;
    //     const { results } = await this.$store.dispatch('sales/fetchSales');
    //     this.items = results;
    //     console.log(results);
    //   } catch (err) {
    //     this.isBusy = false;
    //     this.isLoading = false;
    //   } finally {
    //     this.isBusy = false;
    //     this.isLoading = false;
    //   }
    // },
  },
  async created() {
    this.loadData();
  },
  watch: {
    selectAll(v) {
      if (!v) {
        this.clearSelected();
      } else {
        this.selectAllRows();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.bordered {
  border-left: 1px solid var(--primary);
}
.active {
  background-color: #fff5e9;
  color: #ec5800;
}
.completed {
  background-color: #e8fbf4;
  color: #1d9067;
}
</style>
