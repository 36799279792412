<template>
  <div>
    <!-- <b-row class="mt-2">
      <b-col cols="2">
        <rm-drop-down outlined :options="metricOptions" />
      </b-col>
      <b-col cols="10" />
      <b-col cols="3" v-for="(metric, ix) in clientMetrics" :key="ix">
        <rm-metric-card :metric="metric" />
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col cols="7">
        <div class="">
          <rm-insights />
        </div>
      </b-col>
      <b-col cols="5">
        <div>
          <rm-plot-sales />
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col cols="12">
        <div class="d-flex justify-content-between">
          <rm-rating-card
            title="Best Selling"
            color="success--light"
            action="action"
          >
            <template #body>
              <h4 class="display-6">Lakowe Golf</h4>
              <h6 class="font-weight-bold text-black-50">#5,000,000</h6>
            </template>
          </rm-rating-card>
          <rm-rating-card
            title="Least Selling"
            color="danger--light"
            action="action"
          >
            <template #body>
              <h4 class="display-6">Satelite Town</h4>
              <h6 class="font-weight-bold text-black-50">#5,000,000</h6>
            </template>
          </rm-rating-card>
          <rm-rating-card title="Most Plots Sold" color="success--light">
            <template #body>
              <div
                class="d-flex mb-1 justify-content-between"
                v-for="(plot, index) in mostPlotsSold"
                :key="index"
              >
                <p class="mb-0">{{ plot.name }}</p>
                <span>{{ plot.number }}</span>
              </div>
            </template>
          </rm-rating-card>
          <rm-rating-card title="Least Plots Sold" color="danger--light">
            <template #body>
              <div
                class="d-flex mb-1 justify-content-between"
                v-for="(plot, index) in leastPlotsSold"
                :key="index"
              >
                <p class="mb-0">{{ plot.name }}</p>
                <span>{{ plot.number }}</span>
              </div>
            </template>
          </rm-rating-card>
          <rm-rating-card title="Add" color="transparent" isAdd />
        </div>
      </b-col>
    </b-row> -->
    <b-row class="mt-5">
      <b-col cols="12">
        <rm-sales-table></rm-sales-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// import RmMetricCard from "@/components/global/RmMetricCard.vue";
// import RmInsights from "./components/RmInsights.vue";
// import RmPlotSales from "./components/RmPlotSales.vue";
// import RmRatingCard from "./components/RmRatingsCard.vue";
import RmSalesTable from "./components/RmSalesTable.vue";
export default {
  components: {
    // RmMetricCard,
    // RmInsights,
    // RmPlotSales,
    // RmRatingCard,
    RmSalesTable,
  },
  data: () => ({
    metricOptions: ["All Time"],
    clientMetrics: [
      {
        name: "Total Plots Sold",
        amount: "5000",
        growth: +100,
        icon: "total-clients",
      },
      {
        name: "Completed Sales",
        amount: "3000",
        growth: +15,
        icon: "active-clients",
      },
      {
        name: "Pending Sales",
        amount: "1000",
        growth: +100,
        icon: "inactive-clients",
      },
      {
        name: "Ongoing Sales",
        amount: "1000",
        growth: -22.5,
        icon: "prospective-clients",
      },
    ],
    leastPlotsSold: [
      {
        name: "Lakowe Golf",
        number: 120,
      },
      {
        name: "Tincan Island",
        number: 100,
      },
      {
        name: "Ogbomosho",
        number: 90,
      },
      {
        name: "Lekki Penisula",
        number: 52,
      },
      {
        name: "Eko Atlantic City",
        number: 24,
      },
    ],
    mostPlotsSold: [
      {
        name: "Lakowe Golf",
        number: 120,
      },
      {
        name: "Tincan Island",
        number: 100,
      },
      {
        name: "Ogbomosho",
        number: 90,
      },
      {
        name: "Lekki Penisula",
        number: 52,
      },
      {
        name: "Eko Atlantic City",
        number: 24,
      },
    ],
  }),
  methods: {
    async fetchSales() {
      let { results, count } = await this.$store.dispatch("sales/fetchSales", {
        limit: this.limit,
        offset: this.offset,
      });

      this.sales = results;
      this.totalRows = count;
    },
  },
  created() {
    this.fetchSales();
  },
};
</script>
